import { deepFreeze } from "@/utils";

export const passStatusData = deepFreeze([
  {
    label: "通过",
    value: "1"
  },
  {
    label: "退回",
    value: "2"
  },
  {
    label: "不是改善",
    value: "0"
  }
]);

export const DELAY_PASS_STATUS = deepFreeze([
  {
    label: "通过",
    value: "1"
  },
  {
    label: "退回",
    value: "0"
  }
]);

export const takeInTypes = deepFreeze([
  {
    label: "接收-整改",
    value: "1"
  },
  {
    label: "接收-下发",
    value: "2"
  },
  {
    label: "拒收",
    value: "0"
  }
]);

export const checkedIdeaTypes = deepFreeze([
  {
    label: "合格",
    value: "1"
  },
  {
    label: "不合格",
    value: "0"
  }
]);

export const fixStatusTypes = deepFreeze([
  {
    label: "整改完成",
    value: 1
  },
  {
    label: "整改延期",
    value: 2
  }
]);

// 改善状态配置
/**
 * @key statusName 状态名称
 * @key title 详情标题展示
 * @key route 要跳转的路由
 * @key query 路由参数，主要是权限
 * @key currentStage 当前项目已经到的总阶段
 * @key currentStep 当前我应该停留的阶段
 * @key lastRoute 上一个应该处于哪个阶段，用于权限使用
 */
export const troubleStatusMap = {
  0: {
    statusName: "待提交",
    title: "改善提交",
    route: "improve-trouble-add",
    query: { add: false },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "improve-trouble-info"
  },
  9: {
    statusName: "待重新提交",
    title: "改善重新提交",
    route: "improve-trouble-add",
    query: { add: true },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "improve-trouble-info"
  },
  1: {
    statusName: "待审批",
    title: "改善确认",
    route: "improve-trouble-approval",
    query: { add: false },
    currentStage: 1,
    currentStep: 1,
    lastRoute: "improve-trouble-info"
  },
  2: {
    statusName: "待整改",
    title: "改善整改",
    route: "improve-trouble-modify",
    query: { add: false },
    currentStage: 3,
    currentStep: 3,
    lastRoute: "improve-trouble-receive"
  },
  3: {
    statusName: "待延期审批",
    title: "改善延期审批",
    route: "improve-trouble-delay-approval",
    query: { add: false },
    currentStage: 3,
    currentStep: 3,
    lastRoute: "improve-trouble-modify"
  },
  4: {
    statusName: "待验收",
    title: "改善验收",
    route: "improve-trouble-check",
    currentStage: 4,
    query: { add: false },
    currentStep: 4,
    lastRoute: "improve-trouble-modify"
  },
  5: {
    statusName: "已完成",
    title: "改善完成",
    route: "improve-trouble-check",
    query: { add: false },
    currentStage: 4,
    currentStep: 4,
    lastRoute: "improve-trouble-check"
  },
  7: {
    statusName: "待接收",
    title: "改善接收",
    route: "improve-trouble-receive",
    query: { add: false },
    currentStage: 2,
    currentStep: 2,
    lastRoute: "improve-trouble-approval"
  },
  8: {
    statusName: "已取消",
    title: "改善取消",
    route: "improve-trouble-info",
    query: { add: false },
    currentStage: 0,
    currentStep: 0,
    lastRoute: "improve-trouble-info"
  }
};

export const clickMap = {
  0: {
    status: "",
    statusTitle: "登记",
    statusName: "待提交",
    routeName: "improve-trouble-info",
    currentStep: 0,
    query: { add: false }
  },
  1: {
    status: "",
    statusTitle: "确认",
    statusName: "待审批",
    routeName: "improve-trouble-approval",
    currentStep: 1,
    query: { add: false }
  },
  2: {
    status: "",
    statusTitle: "接收",
    statusName: "待接收",
    routeName: "improve-trouble-receive",
    currentStep: 2,
    // 接收状态是 7
    query: { add: false }
  },
  3: {
    status: "",
    statusTitle: "整改",
    statusName: "待整改",
    routeName: "improve-trouble-modify",
    currentStep: 3,
    // 待整改是 2
    query: { add: false }
  },
  4: {
    status: "",
    statusTitle: "验收",
    statusName: "待验收",
    routeName: "improve-trouble-check",
    currentStep: 4,
    // 待验收是 4
    query: { add: false }
  }
};
